import { gsap } from 'gsap'
import { disableScroll, enableScroll } from "./_helpers";

export default class Navbar {
    constructor(el) {
        this.header = el
        this.burger = this.header.querySelector('.menu-burger')
        this.closeMenuBtn = this.header.querySelector('.close-menu')
        this.mobileNav = this.header.querySelector('.mobile-menu')

        this.init()
    }

    init = () => {
        this.initEvents()
    }

    initEvents = () => {
        window.addEventListener('scroll', (e) => {
            window.scrollY > 0 ? this.header.classList.add('scrolled') : this.header.classList.remove('scrolled')
        })

        this.burger.addEventListener('click', this.openMenu)
        this.closeMenuBtn.addEventListener('click', this.closeMenu)
    }

    openMenu = () => {
        disableScroll()
        this.mobileNav.classList.remove('hidden')

        gsap.to(this.mobileNav, {
            duration: 0.5,
            y: 0,
        })
    }

    closeMenu = () => {
        gsap.to(this.mobileNav, {
            duration: 0.5,
            y: '-100%',
        }).then(() => {
            enableScroll()
            this.mobileNav.classList.add('hidden')
        })
    }

}