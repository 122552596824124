import lazyLoadImages from "./components/lazyLoad";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Swiper, { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay'
import Filter from "./components/Filter";
import {gsap} from "gsap";
import {disableScroll, enableScroll} from "./components/_helpers";
import Navbar from "./components/Navbar";

lazyLoadImages();
document.body.addEventListener("force_lazyload", (e) => {
  lazyLoadImages();
});

window.addEventListener('DOMContentLoaded', () => {
  AOS.init()

  const scrollTop = document.querySelector('.scroll-top')
  scrollTop.addEventListener('click', () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  })

  window.addEventListener('scroll', () => {
    if(window.scrollY > 100) {
      scrollTop.classList.add('active')
    } else {
      scrollTop.classList.remove('active')
    }
  })

  new Navbar(document.querySelector('#header'))

  const swiper = new Swiper('.swiper:not(.featured-clients)', {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 64,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })

  new Swiper('.featured-clients',{
    modules: [Autoplay],
    autoplay: {
      delay: 2200,
    },
    slidesPerView: 2,
    centeredSlides: true,
    spaceBetween: 32,
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 5,
      }
    }
  })

  const filters = document.querySelectorAll('.filters')

  filters.forEach(_filter => {
    new Filter(
        _filter.querySelectorAll('.option'),
        _filter.closest('.filtered-content').querySelectorAll('.content .item')
    )
  })

  const openModalBtn = document.querySelector('#open-contact-modal')
  const contactModal = document.querySelector('#contact-modal')

  gsap.set(contactModal, {
    translateY: -80,
    opacity: 0,
  })

  openModalBtn?.addEventListener('click', () => {
    contactModal.style.display = 'block'

    disableScroll()

    gsap.to(contactModal, {
      translateY: 0,
      opacity: 1,
      duration: 1.3,
    })
  })

  contactModal?.addEventListener('click', ({ target }) => {
    if(!target.classList.contains('wpforms-form')){
      if(target.closest('.wpforms-form') !== null) return

      gsap.to(contactModal, {
        translateY: -80,
        opacity: 0,
        duration: 1.3,
      }).then(() => {
        enableScroll()
        contactModal.style.display = 'none'

        gsap.set(contactModal, {
          translateY: -80,
          opacity: 0,
        })
      })
    }
  })

})
