export default class Filter{
    constructor(filters, products) {
        this.filters = [...filters]
        this.products = [...products]

        this.init()
    }

    init = () => {
        const hash = window.location.hash || '#all'

        this.activeFilter = this.filters.filter(filter => {
            return filter.getAttribute("href") === hash
        })[0]

        this.activeFilter.classList.add('active')
        this.getProductsWithSlug(hash.replace('#', ''))

        this.filters.forEach(filter => {
            filter.addEventListener('click', this.handleFilterChange)
        })
    }

    handleFilterChange = ({ target, target: { hash } }) => {
        if(hash === null || target === null) return

        const slug = hash === null || hash === undefined || target === null ? 'all' : hash.replace('#', '')

        this.activeFilter?.classList.remove('active')
        target.classList.add('active')
        this.activeFilter = target

        this.getProductsWithSlug(slug)
    }

    getProductsWithSlug = (slug) => {
        if(slug === 'all') {
            this.showFilteredProducts(this.products)
            return
        }

        const filtered = this.products.filter(product => {
            if(product.classList.contains('active')) product.classList.remove('active')
            return product.dataset.category === slug
        })

        this.showFilteredProducts(filtered)
    }

    showFilteredProducts = (products) => {
        products.forEach(product => {
            if(!product.classList.contains('active')) product.classList.add('active')
        })
    }
}